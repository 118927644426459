import React, { useState } from 'react';
import {
  Grid,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ButtonWithPopper } from 'components/common/Buttons/ButtonWithPopper';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import {
  TeamMembersWithPermissions,
  Permission,
} from 'modules/prospects/types';

type TeamMemberProspectShareRowProp = {
  teamMember: TeamMembersWithPermissions;
  index: number;
  changePermission: (index: number, permission: Permission) => void;
  changeOwnership: (index: number) => void;
  onRemove: (index: number) => void;
  disabled?: boolean;
  togglePermissionsPopper?: () => void;
  isPermissionsPopperOpen?: boolean;
};

export function TeamMemberProspectShareRow({
  index,
  teamMember,
  changePermission: changePermissionProp,
  changeOwnership: changeOwnershipProp,
  onRemove: onRemoveProp,
  disabled,
  togglePermissionsPopper,
  isPermissionsPopperOpen,
}: TeamMemberProspectShareRowProp) {
  const [buttonLoading, setButtonLoading] = useState(false);

  const openPopper = (event?: React.MouseEvent) => {
    event?.stopPropagation();
    togglePermissionsPopper?.();
  };

  const changePermission = async (permission: Permission) => {
    togglePermissionsPopper?.();
    setButtonLoading(true);
    await changePermissionProp(index, permission);
    setButtonLoading(false);
  };

  const changeOwnership = async () => {
    togglePermissionsPopper?.();
    setButtonLoading(true);
    await changeOwnershipProp(index);
    setButtonLoading(false);
  };

  const onRemove = async () => {
    setButtonLoading(true);
    await onRemoveProp(index);
    setButtonLoading(false);
  };

  const menuItems = [
    ...(teamMember.role !== 'Editor'
      ? [
          {
            onClick: () => changePermission('Editor'),
            icon: <EditIcon />,
            text: 'Set as editor',
          },
        ]
      : []),
    ...(teamMember.role !== 'Viewer'
      ? [
          {
            onClick: () => changePermission('Viewer'),
            icon: <VisibilityIcon />,
            text: 'Set as viewer',
          },
        ]
      : []),
    {
      onClick: changeOwnership,
      icon: <CompareArrowsIcon />,
      text: 'Transfer ownership',
    },
    {
      onClick: onRemove,
      icon: <DeleteIcon />,
      text: 'Remove from list',
    },
  ];

  return (
    <Grid
      xs={12}
      item
      container
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item justifyContent="center" xs="auto">
        <Grid container direction="row" alignItems="center">
          <img
            src={teamMember.avatar}
            style={{ borderRadius: '50%' }}
            alt={`avatar for ${teamMember.name}`}
            width="40"
            height="40"
          />
          <Grid pl={2} item container direction="column" xs="auto">
            <Typography color="black">
              {teamMember.name}
              {disabled && ' (you)'}
            </Typography>
            <Typography color="grey" variant="subtitle1">
              {teamMember.email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <ButtonWithPopper
          open={isPermissionsPopperOpen}
          onClick={openPopper}
          onClose={togglePermissionsPopper}
          label={teamMember.role}
          popperProps={{
            placement: 'bottom-start',
            sx: {
              zIndex: 2000,
            },
          }}
          buttonProps={{
            startIcon: !disabled && <ExpandMore />,
            color: 'grey',
            disabled,
            loading: buttonLoading,
          }}
        >
          {menuItems.map((item, index) => (
            <MenuItem
              key={`${teamMember.name}-${index}`}
              onClick={item.onClick}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.text}</ListItemText>
            </MenuItem>
          ))}
        </ButtonWithPopper>
      </Grid>
    </Grid>
  );
}
