import React, { ReactNode, useState } from 'react';
import {
  Paper,
  Popper,
  PopperProps,
  ClickAwayListener,
  MenuList,
} from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab/';

export type ButtonWithPopperProps = {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onClose?: () => void;
  open?: boolean;
  label: string | JSX.Element;
  buttonProps?: Omit<LoadingButtonProps, 'onClick'>;
  popperProps?: Omit<PopperProps, 'anchorEl' | 'open'>;
  children: ReactNode;
};

/**
 * A component that renders a button that opens a popper. The button will automatically open the popper,
 * unless onclick, onClose, or open are provided, and then the component enters a controlled mode.
 */
export function ButtonWithPopper(props: ButtonWithPopperProps) {
  const {
    label,
    buttonProps,
    popperProps,
    children,
    onClick,
    open: openProp,
    onClose,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl && !onClick ? null : event.currentTarget);
    onClick && onClick(event);
  };

  const handleClickAway = () => {
    onClose ? onClose() : setAnchorEl(null);
  };

  const open = Boolean(onClick ? openProp : anchorEl);

  return (
    <>
      <LoadingButton
        aria-controls="fade-menu"
        aria-haspopup="true"
        disableElevation
        disableFocusRipple
        disableRipple
        onClick={handleClick}
        {...buttonProps}
      >
        {label}
      </LoadingButton>

      <Popper open={open} anchorEl={anchorEl} {...popperProps}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper onClick={handleClickAway}>
            <MenuList autoFocusItem={open}>{children}</MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
