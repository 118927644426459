import React, { useEffect, Fragment, SyntheticEvent, useState } from 'react';
import {
  Autocomplete,
  Container,
  Divider,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { East } from '@mui/icons-material';

const labelStyle = (theme: Theme) => css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: ${theme.spacing(2)}px;
  background-color: ${theme.palette.grey[100]};
  border-radius: ${theme.shape.borderRadius}px;
  gap: ${theme.spacing(6)}px;
`;

export interface CsvUploadModalFieldInfo {
  fieldName: string;
  fieldLabel: string;
}
interface UploadModalProps {
  label: string;
  file: File | null;
  columns: string[];
  setSelectedColumn?: React.Dispatch<React.SetStateAction<string>>;
  csvError: string;
  mainLabel: string;
  fields: CsvUploadModalFieldInfo[];
}

interface CsvUploadModalRowProps {
  fieldName: string;
  fieldLabel: string;
  columns: string[];
  onChange: (
    e: SyntheticEvent,
    fieldName: string,
    value: string | null
  ) => void;
  csvError: string;
}

export interface CsvUploadModalFieldConfig
  extends Record<string, CsvUploadModalFieldInfo[]> {}

export const fieldConfigurations: CsvUploadModalFieldConfig = {
  organizations: [
    { fieldName: 'Domain', fieldLabel: 'Column containing domain' },
  ],
  people: [
    { fieldName: 'Name', fieldLabel: 'Column containing name' },
    { fieldName: 'Email', fieldLabel: 'Column containing email' },
  ],
  'job-titles-keywords': [
    { fieldName: 'Job titles', fieldLabel: `Column containing job titles` },
  ],
};

export function CsvUploadModalRow({
  fieldName,
  fieldLabel,
  columns,
  onChange,
  csvError,
}: CsvUploadModalRowProps) {
  const theme = useTheme();

  return (
    <Grid container direction="row" gap={theme.spacing(3)} alignItems="center">
      <Grid item>
        <span>
          <b>Match</b>
        </span>
        <span className={labelStyle(theme)}>
          {fieldName}
          <East />
        </span>
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        <span>{fieldLabel}</span>
        <Autocomplete
          readOnly={csvError !== ''}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} placeholder="Select column header" />
          )}
          onChange={(e, value) => {
            onChange(e, fieldName, value);
          }}
          options={columns}
        />
      </Grid>
    </Grid>
  );
}

export function CsvUploadModal({
  label,
  file,
  columns,
  setSelectedColumn,
  csvError,
  mainLabel,
  fields,
}: UploadModalProps) {
  const theme = useTheme();
  const [fieldState, setFieldState] = useState({});

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        overflowY: 'scroll',
      }}
    >
      <Typography color={theme.palette.primary.main}>{file?.name}</Typography>

      {fields.map((field, index) => (
        <Fragment key={index}>
          <CsvUploadModalRow
            fieldName={field.fieldName}
            fieldLabel={field.fieldLabel}
            columns={columns}
            csvError={csvError}
            onChange={(_e, fieldName, value) => {
              setFieldState((fieldState) => ({
                ...fieldState,
                [fieldName]: value,
              }));
              if (setSelectedColumn) {
                setSelectedColumn(value || '');
              }
            }}
          />

          {index < fields.length - 1 && <Divider textAlign="left">OR</Divider>}
        </Fragment>
      ))}
      {csvError && (
        <Typography color={theme.palette.error.main}>{csvError}</Typography>
      )}
    </Container>
  );
}
